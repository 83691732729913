import styles from './Card.module.scss';
import { modes } from '../../../App';
import { getAnimals, getFruit, getHouses1, getHouses2, getNature } from '../../data';

const Card = ({ index, mode, card = {}, onCardClick = () => { } }) => {

  const renderCard = (card) => {
    let urls
    switch (mode) {
      case modes.fruit:
        urls = getFruit();
        return <img src={urls[card.value]} className={styles.image} alt='Memory' />
      case modes.animals:
        urls = getAnimals();
        return <img src={urls[card.value]} className={styles.image} alt='Memory' />
      case modes.nature:
        urls = getNature();
        return <img src={urls[card.value]} className={styles.image} alt='Memory' />
      case modes.houses1:
        urls = getHouses1();
        return <img src={urls[card.value]} className={styles.image} alt='Memory' />
      case modes.houses2:
        urls = getHouses2();
        return <img src={urls[card.value]} className={styles.image} alt='Memory' />
      default:
        return card?.value + 1
    }
  }

  return (
    <div className={`${styles["card"]} ${card?.isPressed ? styles["is-pressed"] : ''} ${card?.isGuessed ? styles["is-guessed"] : ''}`} onClick={() => onCardClick(index)}>
      <div className={styles["front"]}></div>
      <div className={styles["back"]}>{renderCard(card)}</div>
    </div>
  )
}

export default Card
