import foto1 from '../img/_MG_6048.jpg';
import foto2 from '../img/1988GB240.jpg';
import foto3 from '../img/1992GB229.jpg';
import foto4 from '../img/champagne1991003.jpg';
import foto5 from '../img/cotswolds1997002.jpg';
import foto6 from '../img/CRW_0413_RJ.jpg';
import foto7 from '../img/CRW_6832_RJ.jpg';
import foto8 from '../img/GECA  18 mei 2021 0005.jpg';
import foto9 from '../img/GECA  20121027  015-2.jpg';
import foto10 from '../img/GECA herfst 2001031.jpg';
import foto11 from '../img/GECA19990410-013.jpg';
import foto12 from '../img/GECA20111030 0002.jpg';
import foto13 from '../img/heihaas012.jpg';
import foto14 from '../img/IMG_0258.jpg';
import foto15 from '../img/IMG_0338.jpg';
import foto16 from '../img/twenthe046.jpg';

const url = 'https://images.unsplash.com/';
const parameters = '?q=80&w=200&h=200&auto=format&fit=crop'
const fruit = [
  'photo-1600626333392-59a20e646d97', // Apples
  'photo-1481349518771-20055b2a7b24', // Banana
  'photo-1559181567-c3190ca9959b',    // Cheries
  'photo-1464454709131-ffd692591ee5', // Strawberies
  'photo-1423483641154-5411ec9c0ddf', // Beries
  'photo-1571575173700-afb9492e6a50', // Melon
  'photo-1515681412668-16d6d4a54ab1', // Raspberries
  'photo-1653192317885-b175cdd33f88', // Kiwi
]
const animals = [
  'photo-1718075834864-c5b769675a0e', // Yak
  'photo-1728591291028-2511e486bf04', // Small bird 
  //'photo-1727838204833-ea9073105675', // Lemur
  'photo-1728528375076-67304d496488', // Eagle
  'photo-1728523294802-f92b7bfe9968', // Frog
  'photo-1728530170057-d7e9765ada75', // White bird
  'photo-1728487877306-6211b309260e', // Dogs
  'photo-1727887746394-8b5c23c98b40', // Fox
  'photo-1724128938077-47dcca1724e2', // Horse
]
const nature = [
  'photo-1727775909609-7cf324b9a46d',
  'photo-1728410594823-5074a3ff0b32',
  'photo-1728321596244-4fabb68c3ac3',
  'photo-1728412390009-1a2d295230dc',
  'photo-1728425964065-c581bc5ec9ae',
  'photo-1728327510555-4e8bc0bbba43',
  'photo-1704664265297-b1410ae6c6f3',
  'photo-1728155253434-262ab74ef031',
]
const houses1 = [
  foto1,
  foto2,
  foto3,
  foto4,
  foto5,
  foto6,
  foto7,
  foto8
]
const houses2 = [
  foto9,
  foto10,
  foto11,
  foto12,
  foto13,
  foto14,
  foto15,
  foto16
]

export const getFruit = () => {
  return fruit.map(fruit => `${url}${fruit}${parameters}`);
}

export const getAnimals = () => {
  return animals.map(animal => `${url}${animal}${parameters}`);
}

export const getNature = () => {
  return nature.map(item => `${url}${item}${parameters}`);
}

export const getHouses1 = () => {
  return houses1;
}

export const getHouses2 = () => {
  return houses2;
}