import React, { useEffect, useMemo, useRef, useState } from 'react';
import Card from './js/components/molecules/Card';
import styles from './App.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRotateLeft, faCoffee, faDownload, faPlay } from '@fortawesome/free-solid-svg-icons';
import '../src/sass/app.scss';
import _ from 'lodash';
import Fireworks from '@fireworks-js/react';

export const modes = {
  numbers: 'NUMBERS',
  //icons: 'ICONS',
  fruit: 'FRUIT',
  animals: 'ANIMALS',
  nature: 'NATURE',
  houses1: 'HOUSES1',
  houses2: 'HOUSES2'
}

function App() {
  const [numberOfCards] = useState(16);
  const [cards, setCards] = useState([]);
  const [moves, setMoves] = useState(0);
  const [accuracy, setAccuracy] = useState(100);
  const [elapsedTime, setElapsedTime] = useState(0);
  const timeout = useRef(null);
  const timer = useRef(null);
  const [time, setTime] = useState('');
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);
  const [isPWAInstalled, setIsPWAInstalled] = useState(false);
  const [mode, setMode] = useState(modes.houses1);
  const fireWorksRef = useRef(null);

  useEffect(() => {
    const handler = e => {
      e.preventDefault();
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler);

    const checkIfAppIsInstalled = async () => {
      if ("getInstalledRelatedApps" in navigator) {
        const relatedApps = await navigator.getInstalledRelatedApps();
        console.log(relatedApps);
        setIsPWAInstalled(relatedApps.length > 0);
      }
    }

    checkIfAppIsInstalled();

    return () => window.removeEventListener("transitionend", handler);
  }, []);

  useEffect(() => {
    const min = Math.floor(elapsedTime / 60);
    const sec = elapsedTime % 60;

    setTime(`${min > 9 ? min : '0' + min}:${sec > 9 ? sec : '0' + sec}`)
  }, [elapsedTime])

  const startTimer = () => {
    if (!timer.current) {
      timer.current = setInterval(() => {
        setElapsedTime(prev => prev + 1)
      }, 1000)
    }
  }

  const stopTimer = () => {
    clearInterval(timer.current);
  }

  const resetTimer = () => {
    setElapsedTime(0);
    timer.current = null;
  }

  const onCardClick = index => {
    const tmpCards = [...cards];

    if (tmpCards.filter(card => card.isPressed && !card.isGuessed).length < 2) {
      tmpCards[index].isPressed = true;
      setCards(tmpCards);
    }

    startTimer();
  }

  const generateCards = () => {
    const tmpCards = [];
    const values = _.shuffle(
      [...Array(numberOfCards / 2).keys()].concat(
        [...Array(numberOfCards / 2).keys()]
      )
    );

    for (let index = 0; index < values.length; index++) {
      tmpCards.push({
        value: values[index],
        isPressed: false,
        isGuessed: false
      });
    }

    setCards(tmpCards);
  }

  const reset = () => {
    setMoves(0);
    setAccuracy(100);
    generateCards();
    stopTimer();
    resetTimer();
  }

  const onClickReset = () => {
    reset();
  }

  const finished = useMemo(() => {
    const guessedCards = cards.filter(card => card.isGuessed);
    return guessedCards.length === numberOfCards
  }, [cards, numberOfCards])

  useEffect(() => {
    if (cards) {
      const tmpCards = [...cards];
      const pressedCards = tmpCards.filter(card => card.isPressed && !card.isGuessed);
      const guessedCards = tmpCards.filter(card => card.isGuessed);
      const [first, second] = pressedCards;

      if (first && second) {
        clearTimeout(timeout.current);
        timeout.current = setTimeout(() => {
          if (first.value === second.value) {
            first.isGuessed = second.isGuessed = true;
          } else {
            first.isPressed = second.isPressed = false;
          }

          setCards(tmpCards);
          setMoves(prev => prev + 1);
        }, 1200)
      }

      if (guessedCards.length === numberOfCards) {
        stopTimer();
      }
    }
  }, [cards, numberOfCards]);

  useEffect(() => {
    if (cards) {
      const tmpCards = [...cards];
      const guessedCards = tmpCards && tmpCards.filter(card => card.isGuessed);

      if (moves === 0) {
        setAccuracy(100)
        return
      }

      if (guessedCards.length === 0) {
        setAccuracy(0)
        return
      }

      setAccuracy(Math.floor(((guessedCards.length / 2) / moves) * 100), 2)
    }
  }, [cards, moves])

  useEffect(() => {
    if (cards.filter(card => card.isPressed).length === 0 && cards.filter(card => card.isGuessed).length === 0) {
      generateCards();
    } else {
      reset();
    }
    // eslint-disable-next-line
  }, [mode]);

  const onClickInstall = evt => {
    evt.preventDefault();
    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
  }

  return (
    <div className={`app ${styles["app"]}`}>
      <header className={styles["header"]}>
        Memory
      </header>
      <h1 className={styles.hidden}>Memory</h1>
      <ul className={styles["cards"]}>
        {cards && cards.map((card, index) => <li key={index}><Card index={index} mode={mode} card={card} onCardClick={onCardClick} /></li>)}
      </ul>
      <ul className={styles["feed-back"]}>
        <li><span>1</span>Level</li>
        <li><span>{time}</span>Time</li>
        <li><span>{moves}</span>Moves</li>
        <li><span>{accuracy}%</span>Accuracy</li>
      </ul>
      <button onClick={onClickReset}><FontAwesomeIcon icon={faArrowRotateLeft} />Reset</button>

      <div className={styles["modes-container"]}>
        <strong>Select your category:</strong>
        <ul className={styles["modes-list"]}>
          {Object.keys(modes).map((mode, key) => <li key={key}>
            <button onClick={evt => setMode(modes[mode])}>{mode}</button>
          </li>)}
        </ul>
      </div>

      {supportsPWA && !isPWAInstalled && <div className={styles["install-banner"]}>
        <p>Play this game anywhere, even offline</p>
        <button onClick={onClickInstall}><FontAwesomeIcon icon={faDownload} />Install</button>
      </div>}

      <div className={styles["support-banner"]}>
        <h2>Do you like this?</h2>
        <p>Please support me to create and maintain this and new applications.</p>
        <button><FontAwesomeIcon icon={faCoffee} />Buy me a coffee!</button>
      </div>

      {finished && <div className={styles["fireworks-container"]}>
        <div className={styles["fireworks-inner-container"]}>
          <Fireworks
            ref={fireWorksRef}
            options={{ opacity: 0.5 }}
            style={{
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              position: 'absolute',
              zIndex: 1
              //background: '#000'
            }}
          />
          <div className={styles["final-panel"]}>
            <h2>Congratulations! You've completed the memory game! 🎉</h2>
            <p>Your focus and attention paid off. Whether it was challenging or a breeze, your memory muscles just got a great workout. Ready for another round or a different challenge?</p>
            <button onClick={onClickReset}><FontAwesomeIcon icon={faPlay} />Play again</button>
          </div>
        </div>
      </div>}
    </div>
  );
}

export default App;
